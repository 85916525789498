<template>
    <div class="adminSettings">
        <template v-if="!selected">
            <v-container fluid>
                <v-row align="start" justify="start">
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        lg="3"
                        xl="2"
                        v-for="(item, i) in arrAdminSettings"
                        :key="i"
                    >
                        <v-hover>
                            <v-card
                                slot-scope="{ hover }"
                                :class="
                                    `elevation-${
                                        hover && !item.disabled
                                            ? 12
                                            : !item.disabled
                                            ? 2
                                            : 1
                                    } ${item.disabled ? 'grey' : ''} ${
                                        !$vuetify.dark
                                            ? 'lighten-5'
                                            : 'darken-5'
                                    }`
                                "
                                class="mx-auto"
                                :to="
                                    !item.disabled
                                        ? {
                                              name: item.route,
                                              params: item.routeParams,
                                          }
                                        : null
                                "
                                height="120"
                            >
                                <div class="text-center pt-3">
                                    <v-chip
                                        v-if="item.disabled"
                                        style="position:absolute;top:-20px;right:-20px;"
                                        class="white--text"
                                        color="info"
                                        >{{ $t('admin.availableSoon') }}</v-chip
                                    >
                                    <v-icon
                                        :size="
                                            hover && !item.disabled ? 40 : 30
                                        "
                                        :color="
                                            hover && !item.disabled
                                                ? 'primary'
                                                : ''
                                        "
                                        >{{ item.icon }}</v-icon
                                    >
                                    <v-card-text>
                                        <span class="text-h6">
                                            {{ $t(item.label) }}
                                        </span>
                                    </v-card-text>
                                </div>
                            </v-card>
                        </v-hover>
                    </v-col>
                </v-row>
            </v-container>
        </template>
        <template v-else-if="selected === 'categories'">
            <GenericAdminCrud
                routeName="AdminLegitimateInterest"
                routeAction="categories"
                :navbarTitle="
                    $t('admin.legitimateInterest.buttonLabelCategories')
                "
                :navbarBackRouteWithoutHistory="{ name: 'AdminLegitimateInterest' }"
                :fetchCalls="{
                    items: '/api/admin/legitimateInterest/categories',
                    categories: null,
                    itemById: '/api/admin/legitimateInterest/category/',
                    edit: '/api/admin/legitimateInterest/editCategory',
                    add: '/api/admin/legitimateInterest/addCategory',
                }"
                :newItemModelFields="{
                    title: null,
                    clientId: null,
                    locale: currentLanguage,
                    translations: categoryDefaultTranslations
                }"
            >
                <template v-slot:editorMask="x">
                    <CategoryEditorMask
                        v-model="x.selectedItem"
                        :key="x.id"
                        :available-languages="availableLanguages"
                        :current-language="currentLanguage"
                        :isWaiting="x.isWaiting"
                        :isModified="x.isModified"
                        @modified="x.onSetModified"
                        @onSaveItem="x.onSaveItem"
                        @onCreateItem="x.onCreateItem"
                    />
                </template>
            </GenericAdminCrud>
        </template>
        <template v-else-if="selected === 'items'">
            <GenericAdminCrud
                routeName="AdminLegitimateInterest"
                routeAction="items"
                :navbarTitle="$t('admin.legitimateInterest.buttonLabelItems')"
                :navbarBackRouteWithoutHistory="{ name: 'AdminLegitimateInterest' }"
                :fetchCalls="{
                    items: '/api/admin/legitimateInterest/items',
                    categories: '/api/admin/legitimateInterest/categories',
                    itemById: '/api/admin/legitimateInterest/item/',
                    delete: '/api/admin/legitimateInterest/delete',
                    edit: '/api/admin/legitimateInterest/edit',
                    add: '/api/admin/legitimateInterest/add',
                }"
                :newItemModelFields="{
                    title: null,
                    clientId: null,
                    categoryId: null,
                    locale: currentLanguage,
                    translations: defaultTranslations
                }"
            >
                <template v-slot:editorMask="x">
                    <ScenarioItemEditorMask
                        v-model="x.selectedItem"
                        :key="x.id"
                        :categoryItems="x.categoryItems"
                        :available-languages="availableLanguages"
                        :current-language="currentLanguage"
                        :isWaiting="x.isWaiting"
                        :isModified="x.isModified"
                        @modified="x.onSetModified"
                        @onSaveItem="x.onSaveItem"
                        @onCreateItem="x.onCreateItem"
                    />
                </template>
            </GenericAdminCrud>
        </template>
    </div>
</template>

<script>
import GenericAdminCrud from '../GenericAdminCrud.vue';
import ScenarioItemEditorMask from './ScenarioItemEditorMask.vue';
import CategoryEditorMask from './CategoryEditorMask.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'AdminLegitimateInterestComp',
    components: {
        GenericAdminCrud,
        ScenarioItemEditorMask,
        CategoryEditorMask,
    },
    i18n: {
        messages: {
            en: require('@/locales/Admin/en.json'),
            de: require('@/locales/Admin/de.json'),
        },
    },
    data() {
        return {
            selected: null,
            arrAdminSettings: [
                {
                    route: 'AdminLegitimateInterest',
                    routeParams: { action: 'categories' },
                    icon: 'mdi-scale-balance',
                    label: 'admin.legitimateInterest.buttonLabelCategories',
                    disabled: false,
                },
                {
                    route: 'AdminLegitimateInterest',
                    routeParams: { action: 'items' },
                    icon: 'mdi-scale-balance',
                    label: 'admin.legitimateInterest.buttonLabelItems',
                    disabled: false,
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            availableLanguages: 'languageSettings/getAvailableLanguages',
            currentLanguage: 'languageSettings/getCurrentLanguage'
        }),
        categoryDefaultTranslations() {
            return this.availableLanguages.map(x => {
                return { 
                    title: '', 
                    locale: x
                };
            });
        },
        defaultTranslations() {
            return this.availableLanguages.map(x => {
                return { 
                    title: '', 
                    interestResponsiblePerson: '', 
                    interestPersonConcerned: '', 
                    necessity: '', 
                    balancingOfInterests: '',
                    locale: x
                };
            });
        }
    },
    created() {
        this.handleRoute(this.$route.params);
    },
    methods: {
        handleRoute(routeParams) {
            this.selected = routeParams.action || false;
        },
    },
    watch: {
        $route(to) {
            this.handleRoute(to.params);
        },
    },
};
</script>
