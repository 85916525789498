<template>
    <v-container fluid class="ma-0 mb-3 pa-0 pl-2 pt-3">
        <v-row class="justify-space-arround">
            <v-col cols="12">
                <v-card class="default" outlined height="100%">
                    <EditorToolbar
                        :headline="model.translations.find(x => x.locale === currentLanguage).title"
                        :isModified="isModified"
                        :isWaiting="isWaiting"
                        :editMode="editMode"
                        @onSaveItem="$emit('onSaveItem')"
                        @onDeleteItem="$emit('onDeleteItem')"
                        @onCreateItem="$emit('onCreateItem')"
                        @onFindUsage="$emit('onFindUsage')"
                        :buttons="['new', 'save', 'translationState']"
                        />
                    <v-card-text class="pa-0">
                        <v-autocomplete
                            :items="categoryItems"
                            v-model="model.category"
                            :label="$t('admin.legitimateInterest.category.label')"
                            item-value="id"
                            item-text="title"
                            :error-messages="
                                errors.collect('legitimateInterestItemCategory')
                            "
                            data-vv-name="legitimateInterestItemCategory"
                            v-validate="'required'"
                        ></v-autocomplete>
                    </v-card-text>
                    <v-card-text class="pa-0">
                        <v-container fluid class="pa-0 ma-0">
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <LeaTranslationTableInput v-model="model.translations" required @modified="$emit('modified', $event)" fieldValue="title" :fieldText="'label'" fieldType="text" />
                                </v-col>
                                <v-col cols="12" lg="6">
                                    <LeaTranslationTableInput v-model="model.translations" required @modified="$emit('modified', $event)" fieldValue="interestResponsiblePerson" :fieldText="$t('admin.legitimateInterest.interestResponsiblePerson.label')" fieldType="textarea" />
                                </v-col>
                                <v-col cols="12" lg="6">
                                    <LeaTranslationTableInput v-model="model.translations" required @modified="$emit('modified', $event)" fieldValue="interestPersonConcerned" :fieldText="$t('admin.legitimateInterest.interestPersonConcerned.label')" fieldType="textarea" />
                                </v-col>
                                <v-col cols="12">
                                    <LeaTranslationTableInput v-model="model.translations" required @modified="$emit('modified', $event)" fieldValue="necessity" :fieldText="$t('admin.legitimateInterest.necessity.label')" fieldType="textarea" />
                                </v-col>
                                <v-col cols="12">
                                    <LeaTranslationTableInput v-model="model.translations" required @modified="$emit('modified', $event)" fieldValue="balancingOfInterests" :fieldText="$t('admin.legitimateInterest.balancingOfInterests.label')" fieldType="textarea" />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { isUUID } from '@/components/vvt/ModelMixin.js';
import EditorToolbar from '@/components/settings/EditorToolbar.vue';
import LeaTranslationTableInput from '@/components/Input/LeaTranslationTableInput';

export default {
    name: 'AdminLegitimateInterestScenarioItemEditorMask',
    inject: {
        $validator: '$validator',
    },
    components: {
        EditorToolbar,
        LeaTranslationTableInput
    },
    props: {
        value: {
            type: Object,
            default: null
        },
        categoryItems: {
            type: Array,
            default: () => []
        },
        availableLanguages: {
            type: Array,
            default: () => []
        },
        currentLanguage: {
            type: String,
            default: null
        },
        isWaiting: {
            type: Boolean,
            default: false
        },
        isModified: {
            type: Boolean,
            default: false
        },
    },
    i18n: {
        messages: {
            en: require('@/locales/Admin/en.json'),
            de: require('@/locales/Admin/de.json'),
        },
    },
    data() {
        return {
            model: this.value,
        };
    },
    mounted() {
        if (isUUID(this.model.id)) {
            this.model.clientId = this.value.id;
        }
    },
    computed: {
        editMode() {
            return !isUUID(this.model.id);
        }
    }
};
</script>
