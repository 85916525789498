<template>
    <div>
        <AdminLegitimateInterestComp />
    </div>
</template>

<script>
import AdminLegitimateInterestComp from '@/components/admin/legitimateInterest/index.vue';

export default {
    name: 'AdminLegitimateInterest',
    components: {
        AdminLegitimateInterestComp,
    },
};
</script>
