var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"adminSettings"},[(!_vm.selected)?[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"start","justify":"start"}},_vm._l((_vm.arrAdminSettings),function(item,i){return _c('v-col',{key:i,attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"2"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return _c('v-card',{staticClass:"mx-auto",class:("elevation-" + (hover && !item.disabled
                                        ? 12
                                        : !item.disabled
                                        ? 2
                                        : 1) + " " + (item.disabled ? 'grey' : '') + " " + (!_vm.$vuetify.dark
                                        ? 'lighten-5'
                                        : 'darken-5')),attrs:{"to":!item.disabled
                                    ? {
                                          name: item.route,
                                          params: item.routeParams,
                                      }
                                    : null,"height":"120"}},[_c('div',{staticClass:"text-center pt-3"},[(item.disabled)?_c('v-chip',{staticClass:"white--text",staticStyle:{"position":"absolute","top":"-20px","right":"-20px"},attrs:{"color":"info"}},[_vm._v(_vm._s(_vm.$t('admin.availableSoon')))]):_vm._e(),_c('v-icon',{attrs:{"size":hover && !item.disabled ? 40 : 30,"color":hover && !item.disabled
                                            ? 'primary'
                                            : ''}},[_vm._v(_vm._s(item.icon))]),_c('v-card-text',[_c('span',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t(item.label))+" ")])])],1)])}}],null,true)})],1)}),1)],1)]:(_vm.selected === 'categories')?[_c('GenericAdminCrud',{attrs:{"routeName":"AdminLegitimateInterest","routeAction":"categories","navbarTitle":_vm.$t('admin.legitimateInterest.buttonLabelCategories'),"navbarBackRouteWithoutHistory":{ name: 'AdminLegitimateInterest' },"fetchCalls":{
                items: '/api/admin/legitimateInterest/categories',
                categories: null,
                itemById: '/api/admin/legitimateInterest/category/',
                edit: '/api/admin/legitimateInterest/editCategory',
                add: '/api/admin/legitimateInterest/addCategory',
            },"newItemModelFields":{
                title: null,
                clientId: null,
                locale: _vm.currentLanguage,
                translations: _vm.categoryDefaultTranslations
            }},scopedSlots:_vm._u([{key:"editorMask",fn:function(x){return [_c('CategoryEditorMask',{key:x.id,attrs:{"available-languages":_vm.availableLanguages,"current-language":_vm.currentLanguage,"isWaiting":x.isWaiting,"isModified":x.isModified},on:{"modified":x.onSetModified,"onSaveItem":x.onSaveItem,"onCreateItem":x.onCreateItem},model:{value:(x.selectedItem),callback:function ($$v) {_vm.$set(x, "selectedItem", $$v)},expression:"x.selectedItem"}})]}}])})]:(_vm.selected === 'items')?[_c('GenericAdminCrud',{attrs:{"routeName":"AdminLegitimateInterest","routeAction":"items","navbarTitle":_vm.$t('admin.legitimateInterest.buttonLabelItems'),"navbarBackRouteWithoutHistory":{ name: 'AdminLegitimateInterest' },"fetchCalls":{
                items: '/api/admin/legitimateInterest/items',
                categories: '/api/admin/legitimateInterest/categories',
                itemById: '/api/admin/legitimateInterest/item/',
                delete: '/api/admin/legitimateInterest/delete',
                edit: '/api/admin/legitimateInterest/edit',
                add: '/api/admin/legitimateInterest/add',
            },"newItemModelFields":{
                title: null,
                clientId: null,
                categoryId: null,
                locale: _vm.currentLanguage,
                translations: _vm.defaultTranslations
            }},scopedSlots:_vm._u([{key:"editorMask",fn:function(x){return [_c('ScenarioItemEditorMask',{key:x.id,attrs:{"categoryItems":x.categoryItems,"available-languages":_vm.availableLanguages,"current-language":_vm.currentLanguage,"isWaiting":x.isWaiting,"isModified":x.isModified},on:{"modified":x.onSetModified,"onSaveItem":x.onSaveItem,"onCreateItem":x.onCreateItem},model:{value:(x.selectedItem),callback:function ($$v) {_vm.$set(x, "selectedItem", $$v)},expression:"x.selectedItem"}})]}}])})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }